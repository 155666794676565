<template>
  <div class="fu-wrap">
    <vue-nav activeIndex='funct'></vue-nav>
    <div class="ban">
      <img src="../assets/images/subana.png" alt="">
    </div>
    <div class="fu1">
      <div class="box1">
        <h2>基础版</h2>
        <h3>适合直营/单门店经营模式</h3>
        <p>PAD端收银、管理端APP、PC端</p>
        <p>（不含顾客端）</p>
        <h4>标准价￥3600/年</h4>
        <h5>活动价<span class="mon">￥1980</span><span class="lor">/年</span></h5>
      </div>
      <div class="box2">
        <h2>标准版</h2>
        <h3>适合直营/联营连锁模式</h3>
        <p>顾客端公众号、小程序（含商城、新客老客</p>
        <p>营销活动功能、线上线下数据打通功能）、</p>
        <p>PAD端收银、管理端APP、PC端</p>
        <h4>标准价￥9800/年</h4>
        <h5>活动价<span class="mon">￥3980</span><span class="lor">/年</span></h5>
      </div>
      <div class="box3">
        <h2>定制版</h2>
        <h3>适合有定制开发需求经营模式</h3>
        <p>开发功能价格按行业标准</p>
        <p>请联系我们...</p>
        <h5>
          <span class="mon">￥？</span>
        </h5>
      </div>
      <p class="o1"> </p>
    </div>
    <div class="fu2">
      <h2>选购任意版本</h2>
      <p>每增加一家连锁店（5家起）收费980/年，每个顾客端上线一个活动收费500元/次，第二年收费参考活动价，</p>
      <p>需要单独部署独立（自己的）服务器的连锁商户，价格另议</p>
    </div>
    <div class="fu3">
      <tr>
        <th></th>
        <th>基础版</th>
        <th>标准版</th>
      </tr>
      <tr>
        <td class="bor_noleft">店务管理：完善的基础门店管理能力</td>
        <td class="bor_no"></td>
        <td class="bor_noright"></td>
      </tr>
      <tr>
        <td>预约管理</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>核销系统</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>收银系统</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>商品管理</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>仓库管理</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>数据统计分析</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>客装、项目管理</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>会议管理</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>消息推送</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <!-- 连锁管理 -->
      <tr>
        <td class="bor_noleft">连锁管理</td>
        <td class="bor_no"></td>
        <td class="bor_noright"></td>
      </tr>
      <tr>
        <td>门店管控</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>权限管理</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>品项管理</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>区域管理后台</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>多级分润体系</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>总部权限管理中心</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <!-- 营销拓客 -->
      <tr>
        <td class="bor_noleft">营销拓客</td>
        <td class="bor_no"></td>
        <td class="bor_noright"></td>
      </tr>
      <tr>
        <td>有奖问答闯关</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>扫码拓客</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>调查问卷</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>推荐下单有礼</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>抽奖砸金蛋</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>注册有礼</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>满减满赠</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>拼团</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>优惠劵分享</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>二级分销</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>秒杀</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <!-- 经营渠道 -->
      <tr>
        <td class="bor_noleft">经营渠道</td>
        <td class="bor_no"></td>
        <td class="bor_noright"></td>
      </tr>
      <tr>
        <td>业务公众号/小程序</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>美好商城</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>APP</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>员工天地</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>员工微店</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>iPAD端</td>
        <td><img src="../assets/images/clo_04.png" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>
      <tr>
        <td>PC端</td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
        <td><img src="../assets/images/clo_06.gif" alt=""></td>
      </tr>

    </div>
  </div>
</template>
<script>
import bnav from './components/b-nav'
export default {
  components: {
    'vue-nav': bnav,
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
@import "../assets/funct.scss";
</style>