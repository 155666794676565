<template>
  <div class="cp_box">
    <vue-nav @add='hand' activeIndex='product'></vue-nav>
    <div class="bl">
      <img src="../assets/images/cp33.png" alt="">
    </div>
    <div class="dh">
      <el-menu :default-active="tab" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#fff" text-color="#000" active-text-color="#e84554">
        <el-menu-item class="menu" index="1">营销拓客</el-menu-item>
        <el-menu-item class="menu" index="2">顾客管理</el-menu-item>
        <el-menu-item class="menu" index="3">员工管理</el-menu-item>
        <el-menu-item class="menu" index="4">单店会议管理</el-menu-item>
        <el-menu-item class="menu" index="5">项目/客装管理</el-menu-item>
        <el-menu-item class="menu" index="6">预约管理</el-menu-item>
        <el-menu-item class="menu" index="7">收银管理</el-menu-item>
        <el-menu-item class="menu" index="8">仓库管理</el-menu-item>
        <el-menu-item class="menu" index="9">连锁管理</el-menu-item>
        <el-menu-item class="menu" index="10">数据分析</el-menu-item>
      </el-menu>
      <div class="triangle">
        <div>
          <i class="iconfont icon-select-arrow is0" :class='val==1?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is1" :class='val==2?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is2" :class='val==3?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is3" :class='val==4?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is4" :class='val==5?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is5" :class='val==6?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is6" :class='val==7?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is7" :class='val==8?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is8" :class='val==9?"is":"on"'></i>
          <i class="iconfont icon-select-arrow is9" :class='val==10?"is":"on"'></i>
        </div>
      </div>
    </div>
    <div v-show="val=='1'">
      <img src="../assets/images/cp32_03.jpg" alt="">
    </div>
    <div v-show="val=='2'">
      <img src="../assets/images/cp2_02.jpg" alt="">
    </div>
    <div v-show="val=='3'">
      <img src="../assets/images/cp3_02.jpg" alt="">
    </div>
    <div v-show="val=='4'">
      <img src="../assets/images/cp4_02.jpg" alt="">
    </div>
    <div v-show="val=='5'">
      <img src="../assets/images/cp5_02.jpg" alt="">
    </div>
    <div v-show="val=='6'">
      <img src="../assets/images/cp6_02.jpg" alt="">
    </div>
    <div v-show="val=='7'">
      <img src="../assets/images/cp7_03.png" alt="">
    </div>
    <div v-show="val=='8'">
      <img src="../assets/images/cp8_02.jpg" alt="">
    </div>
    <div v-show="val=='9'">
      <img src="../assets/images/cp9_02.jpg" alt="">
    </div>
    <div v-show="val=='10'">
      <img src="../assets/images/cp10_02.jpg" alt="">
    </div>
  </div>
</template>
<script>
import bnav from './components/b-nav'
export default {
  components: {
    'vue-nav': bnav,
  },
  data () {
    return {
      tab: "1",
      val: "1",
      a: ''
    }
  },
  created () {


  },
  mounted () {
    this.tab = this.$route.query.val || '1'
    this.val = this.$route.query.val || '1'
  },
  methods: {
    handleSelect (a) {
      this.val = a
    },
    hand (val) {
      console.log(val);
      this.val = val
      this.tab = val
    }
  },
  watch: {
    $route (to) {
      this.val = to.query.val || '1'
      this.tab = to.query.val || '1'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/product.scss";
</style>
